<template>
  <div class="stations">
    <v-row>
      <v-col
        cols="12"
        md="6"
      >
        <breadcrumb :items="breadcrumbs" />
      </v-col>
      <v-col
        cols="12"
        md="6"
        class="text-md-right"
      >
        <v-btn
          v-if="can('station-create')"
          color="primary"
          class="mb-5"
          @click="showAddStationDialog = true"
        >
          <v-icon
            left
            size="22"
          >
            {{ icons.mdiPlus }}
          </v-icon>
          Add Station
        </v-btn>
      </v-col>
    </v-row>
    <v-expand-transition>
      <v-card>
        <v-card-title>
          <v-row
            justify="center"
            class="mb-0"
          >
            <v-col
              cols="12"
              md="4"
            >
              <refresh-button
                :loading="isStationsRefreshing"
                @click="refreshStationsTable()"
              />
            </v-col>
            <v-col
              cols="12"
              md="6"
              class="text-center"
            >
              <search-input
                v-model="search"
                :filters="searchFilters"
                :active-filter="searchColumn"
                @filter-change="onSearchFilterChange"
              />
            </v-col>
            <v-spacer class="d-none d-md-inline" />
          </v-row>
        </v-card-title>
        <v-expand-transition>
          <v-row
            v-if="selected.length > 0 && can('station-delete')"
            class="mb-2"
          >
            <v-col class="text-md-right">
              <v-btn
                color="error"
                class="ma-2"
                @click="showConfirmBulkDeleteDialog = true"
              >
                <v-icon
                  left
                  size="22"
                >
                  {{ icons.mdiDelete }}
                </v-icon>
                Delete Selected
              </v-btn>
            </v-col>
          </v-row>
        </v-expand-transition>
        <v-data-table
          v-model="selected"
          :headers="headers"
          :items="stations"
          :loading="isStationsLoading"
          :server-items-length="pagination.totalItems"
          :options.sync="options"
          :footer-props="footerProps"
          show-select
        >
          <template v-slot:item.actions="{ item }">
            <v-tooltip
              v-if="can('station-edit')"
              bottom
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  ma-4
                  v-bind="attrs"
                  text
                  icon
                  @click="editStation(item)"
                  v-on="on"
                >
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ icons.mdiPencil }}
                  </v-icon>
                </v-btn>
              </template>
              <span>Edit</span>
            </v-tooltip>
            <v-tooltip
              v-if="can('station-delete')"
              bottom
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  ma-4
                  v-bind="attrs"
                  text
                  icon
                  @click="showDeleteStationDialog(item.id)"
                  v-on="on"
                >
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ icons.mdiDelete }}
                  </v-icon>
                </v-btn>
              </template>
              <span>Delete</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card>
    </v-expand-transition>
    <add-station-dialog
      :show-dialog="showAddStationDialog"
      :station="station"
      @close="
        showAddStationDialog = false
        station = {}
      "
      @station-added="onStationAdded"
      @station-updated="onStationUpdated"
    />
    <confirm-dialog
      :show-dialog="showConfirmDeleteDialog"
      :is-agree-button-loading="isConfirmDeleteDialogButtonLoading"
      :agree-text="'Delete'"
      @cancel="showConfirmDeleteDialog = false"
      @agree="deleteStation()"
    />
    <confirm-dialog
      :show-dialog="showConfirmBulkDeleteDialog"
      :message="'Are you sure you want to delete ' + selected.length + ' records?'"
      :is-agree-button-loading="isConfirmBulkDeleteDialogButtonLoading"
      :agree-text="'Delete'"
      @cancel="showConfirmBulkDeleteDialog = false"
      @agree="deleteSelectedStations()"
    />
  </div>
</template>
<script>
import axios from 'axios'
import { mapActions } from 'vuex'
import _ from 'lodash'
import {
  mdiMagnify, mdiPencil, mdiDelete, mdiPlus,
} from '@mdi/js'
import AddStationDialog from '@/components/dialogs/AddStationDialog.vue'
import ConfirmDialog from '@/components/dialogs/ConfirmDialog.vue'
import BreadCrumb from '@/components/partials/BreadCrumb.vue'
import SearchInput from '@/components/partials/SearchInput.vue'
import RefreshButton from '@/components/partials/RefreshButton.vue'
import hasPermission from '@/mixins/hasPermission'

export default {
  components: {
    AddStationDialog,
    ConfirmDialog,
    breadcrumb: BreadCrumb,
    SearchInput,
    RefreshButton,
  },
  mixins: [hasPermission],
  data() {
    return {
      showAddStationDialog: false,
      showConfirmDeleteDialog: false,
      showConfirmBulkDeleteDialog: false,
      isConfirmDeleteDialogButtonLoading: false,
      isConfirmBulkDeleteDialogButtonLoading: false,
      isStationsRefreshing: false,
      toDelete: null,
      options: {
        sortBy: ['created_at'],
        sortDesc: [true],
        page: 1,
        pageCount: 1,
        itemsPerPage: 10,
        totalItems: 0,
      },
      search: '',
      pagination: {
        totalItems: 0,
      },
      footerProps: {
        itemsPerPageOptions: [5, 10, 25, 50, 100],
      },
      selected: [],
      headers: [
        {
          text: 'Name',
          value: 'name',
        },
        {
          text: 'Location',
          value: 'location',
        },
        {
          text: 'Paybill Number',
          value: 'paybill_number',
        },
        {
          text: 'No. of Customers',
          value: 'customers_count',
          sortable: false,
        },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      searchColumn: 'name',
      searchFilters: [
        {
          text: 'Name',
          value: 'name',
        },
        {
          text: 'Location',
          value: 'location',
        },
        {
          text: 'Paybill Number',
          value: 'paybill_number',
        },
      ],
      icons: {
        mdiMagnify,
        mdiPencil,
        mdiDelete,
        mdiPlus,
      },
      station: {},
      stationId: this.$route.params.id,
      stations: [],
      isStationsLoading: true,
      stationsUrl: 'stations',
      breadcrumbs: [
        {
          text: 'Dashboard',
          disabled: false,
          to: { name: 'dashboard' },
        },
        {
          text: 'Stations',
          disabled: false,
          to: { name: 'stations' },
        },
      ],
    }
  },
  computed: {
    refreshTable() {
      return `${this.stationUrl}|${this.search}|${this.sortBy}|${this.sortDesc}`
    },
  },
  watch: {
    options: {
      handler() {
        this.getStations()
      },
      deep: true,
    },
    refreshTable() {
      this.getStations()
    },
    searchColumn() {
      if (this.search !== '') {
        this.getStations()
      }
    },
  },
  mounted() {
    if (!this.can('station-edit') && !this.can('station-delete')) {
      this.removeActionsHeader()
    }
    this.getStations()
  },
  methods: {
    ...mapActions(['refreshStations']),
    getStations: _.debounce(function () {
      this.isStationsLoading = true
      const {
        sortBy, sortDesc, page, itemsPerPage,
      } = this.options
      const sortOrder = sortDesc[0] ? 'desc' : 'asc'
      axios
        .get(
          `${this.stationsUrl}?paginate=true&sortBy=${sortBy[0]}&sortDirection=${sortOrder}&search=${this.search}&searchColumn=${this.searchColumn}&page=${page}&perPage=${itemsPerPage}`,
        )
        .then(response => {
          this.stations = response.data.data
          this.pagination.totalItems = response.data.total
        })
        .catch(error => {
          this.$toast.error(error.response.data.message)
        }).finally(() => {
          this.isStationsLoading = false
          this.isStationsRefreshing = false
        })
    }, 500),
    refreshStationsTable() {
      this.isStationsRefreshing = true
      this.getStations()
    },
    editStation(station) {
      this.station = station
      this.showAddStationDialog = true
    },
    showDeleteStationDialog(id) {
      this.showConfirmDeleteDialog = true
      this.toDelete = id
    },
    deleteSelectedStations() {
      this.isConfirmBulkDeleteDialogButtonLoading = true
      const selectedIds = this.selected.map(station => station.id)

      axios
        .delete('bulk-delete/stations', { data: { ids: selectedIds } })
        .then(() => {
          this.selected = []
          this.getStations()
          this.refreshStations(true)
        })
        .catch(error => {
          this.$toast.error(error.response.data.message)
        }).finally(() => {
          this.showConfirmBulkDeleteDialog = false
          this.isConfirmBulkDeleteDialogButtonLoading = false
        })
    },
    deleteStation() {
      this.isConfirmDeleteDialogButtonLoading = true
      axios
        .delete(`stations/${this.toDelete}`)
        .then(() => {
          this.refreshStationsTable()
          this.refreshStations(true)
        }).catch(error => {
          this.$toast.error(error.response.data.message)
        }).finally(() => {
          this.showConfirmDeleteDialog = false
          this.isConfirmDeleteDialogButtonLoading = false
        })
    },
    onStationAdded() {
      this.refreshStationsTable()
      this.refreshStations(true)
    },
    onStationUpdated() {
      this.refreshStationsTable()
      this.refreshStations(true)
    },
    onPageChange() {
      this.getStations()
    },
    onSearchFilterChange(filter) {
      this.searchColumn = filter
    },
    removeActionsHeader() {
      this.headers.pop()
    },
  },
}
</script>
